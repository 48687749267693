import styled, { css } from 'styled-components';
import { ButtonContainer } from 'combinezone/core';

export const FiltersOut = styled.div`
  display: grid;
  grid-template-rows: 1fr 72px;
  grid-template-areas:
    'content'
    'reset';
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const FiltersIn = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  overflow: auto;
  padding: 16px;
  gap: 16px;
`;

export const FiltersReset = styled.div`
  grid-area: reset;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  height: 72px;
  padding: 16px;
  ${ButtonContainer} {
    width: 100%;
    justify-content: center;
  }
`;
