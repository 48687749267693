import {
  createEnumArrayParam,
  createEnumParam,
  NumberParam,
  withDefault,
} from 'serialize-query-params';
import { ArrayParam, BooleanParam, StringParam } from 'use-query-params';
import {
  EntitySources,
  FromToType,
  PriorityType,
  Service,
  SourceName,
  States,
  SubService,
  Tonality,
} from '#Models/common';
import { DateTime } from 'luxon';

const BASE_QUERY_PARAMS_CONFIG = {
  id: withDefault(StringParam, undefined, false),
  hidden: withDefault(BooleanParam, undefined, false),
  tags_operand: withDefault(
    createEnumParam(['and', 'or', 'nand', 'nor']),
    'or',
    false,
  ),
  tags_values: withDefault(ArrayParam, undefined, false),
  states: createEnumArrayParam(States),
  sources: createEnumArrayParam(EntitySources),
  valueRegex: withDefault(StringParam, undefined, false),
  field: withDefault(
    createEnumParam([FromToType.Created, FromToType.Updated]),
    FromToType.Updated,
    false,
  ),
  from: withDefault(
    NumberParam,
    Math.floor(DateTime.now().minus({ month: 1 }).startOf('day').toSeconds()),
    false,
  ),
  to: withDefault(
    NumberParam,
    Math.floor(DateTime.now().endOf('day').toSeconds()),
    false,
  ),
  service: withDefault(
    createEnumParam([
      Service.Database,
      Service.FraudulentResource,
      Service.LimitedAccess,
      Service.MassMedia,
      Service.MobileApplication,
      Service.SharingPlatform,
      Service.SocialAccount,
      Service.SocialMedia,
      SubService.Fraud,
      SubService.Suspicious,
      SubService.Phishing,
    ]),
    undefined,
    false,
  ),
  priority: createEnumArrayParam([
    PriorityType.Low,
    PriorityType.Medium,
    PriorityType.High,
  ]),
  tool: withDefault(StringParam, undefined, false),
  sourceName: withDefault(
    createEnumArrayParam([
      SourceName.Avito,
      SourceName.Facebook,
      SourceName.Instagram,
      SourceName.Ok,
      SourceName.Other,
      SourceName.Telegram,
      SourceName.Vk,
      SourceName.GitHub,
      SourceName.GooglePlay,
      SourceName.AppStore,
      SourceName.Forum,
      SourceName.PasteBin,
      SourceName.TikTok,
      SourceName.Trello,
      SourceName.Twitter,
      SourceName.Youla,
      SourceName.YouTube,
      SourceName.X,
    ]),
    undefined,
    false,
  ),
  tonality: withDefault(
    createEnumArrayParam([
      Tonality.Negative,
      Tonality.Neutral,
      Tonality.Positive,
    ]),
    undefined,
    false,
  ),
};
export default BASE_QUERY_PARAMS_CONFIG;
