import React, { FC, useMemo } from 'react';

import { Factoid, Spacer, Text } from 'combinezone/core';

import { DisableOneTimePasswordOut } from './DisableOneTimePassword.styles';
import { StyledButtonPrimary } from '#Modules/Auth/Layout/Layout.styles';

const DisableOneTimePassword: FC = () => {
  const mailto = useMemo<string>(() => {
    const subject = encodeURIComponent(
      'Нет доступа к приложению Google Authenticator',
    );
    const body = encodeURIComponent('Опишите вашу проблему');
    return `mailto:ti@bi.zone?subject=${subject}&body=${body}`;
  }, []);

  return (
    <DisableOneTimePasswordOut>
      <Factoid size="md">Нет доступа к приложению</Factoid>
      <Spacer height={32} />
      <Text>
        Если у вас нет доступа к приложению, обратитесь в нашу службу поддержки.
        Мы поможем вам отключить двухфакторную аутентификацию
      </Text>
      <Spacer height={32} />
      <StyledButtonPrimary
        testId="signIn"
        type="submit"
        color="#fff"
        onClick={() => window.location.assign(mailto)}
      >
        Написать в поддержку
      </StyledButtonPrimary>
    </DisableOneTimePasswordOut>
  );
};

export default DisableOneTimePassword;
DisableOneTimePassword.displayName = 'DisableOneTimePassword';
