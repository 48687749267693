// @ts-nocheck

import React, { FC, useCallback } from 'react';
import FilterBase from './Filter_Base';
import { FilterName } from '../models';
import { ToggleGroup } from 'combinezone/core';
import { useQueryParam } from 'use-query-params';
import { SmileHappy, SmileNeutral, SmileSad } from 'combinezone/icons';
import { Tonality } from '../../../Models/common';
import { createEnumArrayParam } from 'serialize-query-params';
import { withDefault } from 'serialize-query-params';

const FilterTonality: FC = () => {
  const [value, setValue] = useQueryParam(
    FilterName.Tonality,
    withDefault(
      createEnumArrayParam([
        Tonality.Negative,
        Tonality.Neutral,
        Tonality.Positive,
      ]),
      undefined,
      false,
    ),
    {
      removeDefaultsFromUrl: true,
      updateType: 'replaceIn',
    },
  );

  const handleClick = useCallback(
    (current: typeof Tonality[keyof typeof Tonality] | undefined) => () => {
      if (current === undefined) {
        setValue(undefined);
      } else if (value === undefined) {
        setValue([current]);
      } else if (Array.isArray(value)) {
        if (value.includes(current)) {
          const newValue = value.filter((v) => v !== current);
          setValue(newValue.length > 0 ? newValue : undefined);
        } else {
          setValue([...value, current]);
        }
      }
    },
    [value],
  );

  return (
    <FilterBase label={'Тональность'}>
      <ToggleGroup
        items={[
          {
            testId: `FilterTonality__all`,
            content: 'Все',
            value: undefined,
            isActive: value === undefined,
            onClick: handleClick(undefined),
          },
          {
            testId: `FilterTonality__${Tonality.Negative}`,
            content: <SmileSad color="#E8594F" />,
            value: Tonality.Negative,
            isActive: Array.isArray(value) && value.includes(Tonality.Negative),
            onClick: handleClick(Tonality.Negative),
          },
          {
            testId: `FilterTonality__${Tonality.Neutral}`,
            content: <SmileNeutral />,
            value: Tonality.Neutral,
            isActive: Array.isArray(value) && value.includes(Tonality.Neutral),
            onClick: handleClick(Tonality.Neutral),
          },
          {
            testId: `FilterTonality__${Tonality.Positive}`,
            content: <SmileHappy color="#7AC360" />,
            value: Tonality.Positive,
            isActive: Array.isArray(value) && value.includes(Tonality.Positive),
            onClick: handleClick(Tonality.Positive),
          },
        ]}
      />
    </FilterBase>
  );
};

export default FilterTonality;
FilterTonality.displayName = 'FilterTonality';
