import React, { FC, useCallback } from 'react';
import { Form } from '#Form';
import FormSubmit from '#Form/Form_Actions';
import { Button, Flex, Modal, useToast } from 'combinezone/core';
import UpsertSubscriptionFields from './UpsertSubscription_Fields';
import { useMutation } from 'react-relay';
import mutation from './UpsertSubscription.graphql';
import { UpsertSubscriptionMutation } from '#__artifacts/UpsertSubscriptionMutation.graphql';
import { Event, EventType, Subscription, StateType } from '#Models/common';
import { emailPeriods } from '#Modules/Profile/Components/Subscription/models';
import { useAccount } from '#Providers/AccountProvider/context';
import { camelCase, upperFirst } from 'lodash';

const FORM_ID = 'UpsertSubscriptionsForm';

const UpsertSubscriptionModal: FC<{
  onClose: () => void;
  onSuccessCallback?: () => void;
  defaultValues?: any;
}> = ({ onClose, onSuccessCallback, defaultValues = {} }) => {
  const { toastSuccess, toastError } = useToast();
  const [upsertSubscription, isInProgress] =
    useMutation<UpsertSubscriptionMutation>(mutation);

  const isEdit = !!defaultValues?.id;
  const { account, isAdmin } = useAccount();

  const formDefaults = {
    events: defaultValues?.query?.events
      ? Object.entries(defaultValues?.query?.events).reduce(
          (sum, [eventName, value]) => {
            if (value) sum.push(eventName as EventType);
            return sum;
          },
          [] as EventType[],
        )
      : [Event.Created],
    emailPeriod: defaultValues?.emailPeriod
      ? emailPeriods[defaultValues.emailPeriod]?.value ?? 'PT15M'
      : 'PT15M',
    subscriptionType: defaultValues?.query?.entities
      ? Subscription.Individual
      : Subscription.General,
    name: defaultValues?.name,
    states: defaultValues?.query?.events?.stateChanged?.to as StateType[],
    id: defaultValues?.id,
    service: undefined,
    organization:
      defaultValues?.query?.actor?.organization?.include[0] ?? 'all',
  };

  if (defaultValues?.query?.entities) {
    Object.entries(defaultValues.query.entities).forEach(
      ([serviceName, filter]) => {
        if (filter) {
          //@ts-ignore
          formDefaults.service = upperFirst(serviceName); // один вариант
        }
      },
    );
  }

  const onSubmit = useCallback(
    ({
      emailPeriod,
      events,
      name,
      service,
      subscriptionType,
      states,
      id,
      organization,
    }: any) => {
      const data: Record<string, any> = {
        name,
        emailPeriod,
        id,
      };
      const query: Record<string, any> = { events: {} };

      events.forEach((eventName: EventType) => {
        query.events[eventName] = {};
      });

      if (subscriptionType === 'individual') {
        query.entities = {
          [camelCase(service)]: {},
        };
        if (events.includes(Event.StateChanged) && states?.length > 0) {
          query.events[Event.StateChanged] = { to: states };
        }
      }
      query.actor = {
        login: {
          exclude: [account.login],
        },
        organization: {
          include: [organization],
        },
      };
      data.query = query;

      if (organization && isAdmin) {
        if (organization === 'all') {
          query.actor.organization = null;
        } else {
          query.actor.organization.include = [organization];
        }
      }

      upsertSubscription({
        variables: {
          // @ts-ignore
          data,
        },
        onCompleted: (response) => {
          // @ts-ignore
          if (response?.upsertNotificationSubscriptions?.data?.length > 0) {
            toastSuccess({
              title: isEdit ? 'Изменения сохранены' : 'Подписка создана',
              durationMs: 1000,
              autoClose: true,
              pauseOnHover: false,
            });
            onSuccessCallback?.();
            onClose();
          } else {
            toastError({
              title: 'Что-то пошло не так...',
              message: 'Повторите попытку позже',
            });
          }
        },
        onError: () => {
          toastError({
            title: 'Что-то пошло не так...',
            message: 'Повторите попытку позже',
          });
        },
      });
    },
    [
      onSuccessCallback,
      onClose,
      upsertSubscription,
      toastSuccess,
      toastError,
      isEdit,
      account,
    ],
  );

  return (
    <Form onSubmit={onSubmit} formId={FORM_ID} defaultValues={formDefaults}>
      <Modal
        testId="UpsertSubscriptionsModal"
        title={isEdit ? 'Редактирование подписки' : 'Создание подписки'}
        size="md"
        onClose={onClose}
        content={<UpsertSubscriptionFields />}
        footerContent={
          <Flex justify="flex-end" gap="8px">
            <FormSubmit formId={FORM_ID} isLoading={isInProgress}>
              {isEdit ? 'Сохранить' : 'Создать'}
            </FormSubmit>
            <Button testId="testId" onClick={onClose}>
              Отменить
            </Button>
          </Flex>
        }
      />
    </Form>
  );
};

UpsertSubscriptionModal.displayName = 'UpsertSubscriptionModal';
export default UpsertSubscriptionModal;
