import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Badge, TextWrapper } from 'combinezone/core';

export const ProductLogoOut = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  color: #fff;
  text-decoration: none;
  padding: 0 0 0 16px;
  & span {
    opacity: 0.9;
  }
`;
export const Logo = styled.img`
  display: block;
  width: 20px;
  height: 20px;
`;

export const LogoBadge = styled(Badge)`
  ${TextWrapper} {
    color: #209bcf;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
  }
  radius: 4px;
  border: 1px solid #209bcf;
`;

export const LogoOut = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 40px;
`;
